<template>
  <div>
    <v-card
      elevation="0"
      class="mx-auto my-15 animate__animated animate__fadeIn"
      width="350"
      v-show="!done"
    >
      <v-card-title class="justify-center">パスワード再設定</v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-row class="ma-5">
            <v-text-field label="ID" v-model="id" readonly></v-text-field>
          </v-row>
          <v-row class="ma-5">
            <v-text-field
              label="新しいパスワード"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :rules="rules"
              hint="6文字以上(半角英数字)"
              persistent-hint
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-btn
              color="primary"
              :loading="loading"
              @click="regist()"
              :disabled="!valid"
              text
              >登録</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-form>
      <v-dialog v-model="error" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> </v-card-title>
          <v-card-text>{{ msg }}</v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card
      elevation="0"
      class="mx-auto my-15 animate__animated animate__fadeIn"
      width="350"
      v-show="done"
      ><v-container
        ><v-row justify="center" align="center">{{ msg }}</v-row
        ><v-row justify="center" align="center">
          <router-link to="/login">ログイン画面へ遷移する</router-link></v-row
        ></v-container
      ></v-card
    >
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",

  data: () => ({
    id: "",
    password: "",
    show1: false,
    error: false,
    msg: "",
    rules: [
      (v) => !!v || "入力してください",
      (v) => /^[a-zA-Z0-9]+$/.test(v) || "半角英数字を入力してください",
      (v) => (v && v.length >= 6) || "6文字以上で入力してください",
    ],
    valid: true,
    loading: false,
    done: false,
  }),
  components: {},
  mounted() {
    this.check();
  },
  methods: {
    check() {
      axios
        .get(process.env.VUE_APP_API_URL + "/param-check", {
          params: {
            hash: this.$route.query.p,
          },
        })
        .then((response) => {
          if (response.data.result == "NG") {
            this.msg = response.data.msg;
            this.error = true;
          } else {
            this.id = response.data.id;
          }
        });
    },
    regist() {
      if (this.$refs.form.validate()) {
        this.check();
        if (!this.error) {
          axios
            .post(process.env.VUE_APP_API_URL + "/regist-new-password", {
              hash: this.$route.query.p,
              password: this.password,
            })
            .then((response) => {
              this.msg = response.data.msg;
              this.done = true;
            });
        }
      }
    },
  },
};
</script>
